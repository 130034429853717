export const makeTree = (cat, parent, normalizer = item => item, filter = item => true) => {
  let node = null

  cat
    .map(normalizer)
    .filter(filter)
    .filter(n => (n.category ? n.category.id : null) === (parent ? parent.id : null))
    .forEach((d) => {
      if (!node) {
        node = []
      }

      const children = makeTree(cat, d, normalizer, filter)
      const createdNode = {
        ...d
      }
      if (children && children.length) {
        createdNode.children = children
      }
      node.push(createdNode)
    })
  return node
}

export const getCleanCategoriesList = (list) => {
  return (list || []).map((item) => {
    return {
      ...item,
      description: null,
      styles: (item.styles || []).map((style) => {
        return { ...style, description: null }
      })
    }
  })
}

export const getCleanStyleList = (list) => {
  return (list || []).map((item) => {
    return {
      ...item,
      description: null,
      categories: (item.categories || []).map((category) => {
        return { ...category, description: null }
      })
    }
  })
}

export const getCleanItemList = (list) => {
  return (list || []).map((item) => {
    return {
      id: item.id,
      slug: item.slug,
      name: item.name,
      tags: item.tags,
      price: item.price,
      brand: item.brand,
      photo_gallery: item.photo_gallery,
      photo_main: item.photo_main,
      description: null,
      styles: getCleanStyleList(item.styles || []),
      categories: getCleanCategoriesList(item.categories || []),
      videos: item.videos
    }
  })
}

export const getCleanItemForArticle = (item) => {
  return {
    id: item.id,
    slug: item.slug,
    name: item.name,
    tags: item.tags,
    price: item.price,
    brand: getCleanBrand(item.brand),
    photo_gallery: item.photo_gallery,
    photo_main: item.photo_main,
    description: null,
    styles: getCleanStyleList(item.styles || []),
    categories: getCleanCategoriesList(item.categories || []),
    videos: item.videos
  }
}
export const getCleanBrand = (brand) => {
  const clone = {
    ...brand
  }
  clone.description = null
  return clone
}

export const getCleanItem = (item) => {
  item.photo_gallery = item.photo_gallery.filter(item => Object.keys(item).length > 0)
  return item
}

export const generateModuleBySlug = (slug) => {
  return {
    namespaced: true,
    state: () => (getCategoryState(slug)),
    mutations: getCategoryMutations(slug),
    getters: getCategoryGetters(slug)
  }
}

export const getCategoryState = (categorySlug) => {
  return {
    [`${categorySlug}_Styles`]: null,
    [`${categorySlug}_Brands`]: null,
    [`${categorySlug}_Colors`]: null
  }
}

export const getCategoryMutations = (categorySlug) => {
  return {
    [`set_${categorySlug}_Styles`] (state, list) { state[`${categorySlug}_Styles`] = getCleanStyleList(list) },
    [`set_${categorySlug}_Brands`] (state, list) { state[`${categorySlug}_Brands`] = list.map(getCleanBrand) },
    [`set_${categorySlug}_Colors`] (state, list) { state[`${categorySlug}_Colors`] = list }
  }
}

export const getCategoryGetters = (categorySlug) => {
  return {
    [getStylesGetterName(categorySlug)] (state) {
      if (!state[`${categorySlug}_Styles`]) {
        return []
      }

      return state[`${categorySlug}_Styles`]
        .concat()
        .sort((style1, style2) =>
          style1.sort - style2.sort === 0 ? style1.name.localeCompare(style2.name) : style1.sort - style2.sort
        )
    },
    [getBrandsGetterName(categorySlug)] (state, _, rootState) {
      if (!rootState.categories || !state[`${categorySlug}_Brands`] || !state[`${categorySlug}_Brands`].length) {
        return []
      }
      const categoryObject = rootState.categories.find(cat => cat.slug === categorySlug)
      const categoryObjectId = categoryObject ? categoryObject.id : 0

      return state[`${categorySlug}_Brands`].concat().sort((brand1, brand2) => {
        const leftBrandSorting = brand1.sorting.find(sortObject => sortObject.category === categoryObjectId)
        const rightBrandSorting = brand2.sorting.find(sortObject => sortObject.category === categoryObjectId)

        const leftSort = leftBrandSorting ? leftBrandSorting.sort : 0
        const rightSort = rightBrandSorting ? rightBrandSorting.sort : 0

        return leftSort - rightSort === 0 ? brand1.name_eng.localeCompare(brand2.name_eng) : leftSort - rightSort
      })
    },
    [getColorsGetterName(categorySlug)] (state) {
      if (!state[`${categorySlug}_Colors`]) {
        return []
      }

      return state[`${categorySlug}_Colors`]
        .concat()
    }
  }
}

export const getCategoryMapGetters = (categorySlugs, { colors = false, brands = false, styles = false }) => {
  const result = {}

  categorySlugs.forEach((slug) => {
    if (styles) {
      result[getStylesGetterName(slug)] = getMappedStylesGetterName(slug)
    }
    if (brands) {
      result[getBrandsGetterName(slug)] = getMappedBrandsGetterName(slug)
    }
    if (colors) {
      result[getColorsGetterName(slug)] = getMappedColorsGetterName(slug)
    }
  })

  return result
}

export const getBrandsGetterName = slug => `get_${slug}_Brands`
export const getColorsGetterName = slug => `get_${slug}_Colors`
export const getStylesGetterName = slug => `get_${slug}_Styles`
export const getMappedBrandsGetterName = slug => `${slug}/${getBrandsGetterName(slug)}`
export const getMappedStylesGetterName = slug => `${slug}/${getStylesGetterName(slug)}`
export const getMappedColorsGetterName = slug => `${slug}/${getColorsGetterName(slug)}`

function exists (variable) {
  return (typeof variable !== 'undefined' && variable != null) || variable === 0
}

export const cleanObjectFromNull = (obj) => {
  Object.keys(obj).forEach(key => !exists(obj[key]) ? delete obj[key] : null)
  return obj
}

export const replaceWithFilters = (text, templateString, value) => {
  if (value.toString() === '') { return text }

  const regExpWithoutFilter = new RegExp(`{${templateString}}`, 'g')
  const regExpWithFilter = new RegExp(`{${templateString}\\|lowerCase}`, 'g')

  text = text.replace(regExpWithoutFilter, value.toString())
  text = text.replace(regExpWithFilter, value.toString().toLowerCase())

  return text
}
